.form
  padding: 32px
  max-width: 1200px
  margin: 0 auto
  background: #fff
  min-height: 100vh

.section
  width: 100%
  padding: 24px
  background: #fff
  border-radius: 12px
  flex-direction: column
  align-items: flex-start
  border: 1px solid #eaeaea
  margin-bottom: 24px

.sectionTitle
  font-size: 18px
  font-weight: 600
  color: #11142D
  margin-bottom: 20px
  display: flex
  align-items: center
  gap: 8px
  
  &::before
    content: ""
    width: 3px
    height: 18px
    background: #935af6
    border-radius: 2px

.radioGroup
  background: #f8f9fa
  padding: 16px
  border-radius: 8px
  border: 1px solid #eaeaea
  width: 100%
  
  label
    font-weight: 500
    font-size: 14px
    color: #495057

.radio
  &[data-checked]
    background: #935af6
    border-color: #935af6

// .input
//   height: 42px
//   border-radius: 8px 
//   border: none
//   font-size: 14px
//   background: #f8f9fa
  
//   &::placeholder
//     color: #adb5bd
  
//   &:focus
//     background: #fff
//     box-shadow: inset 0 0 0 1px #935af6

.inputWrapper
  position: relative
  width: 100%
  
  &::after
    content: ""
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 1px
    background: #eaeaea

.inputLabel
  font-size: 14px
  font-weight: 500
  margin-bottom: 8px
  color: #495057

.button
  height: 42px
  font-weight: 500
  border-radius: 8px
  font-size: 14px
  padding: 0 20px

.buttonLight
  composes: button
  background: #f8f9fa
  color: #495057
  border: 1px solid #eaeaea

.buttonFilled  
  composes: button
  background: #935af6
  border: none

.dropdown
  margin-top: 4px
  border: none
  border-radius: 8px
  background: #fff
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1)
  padding: 4px
  overflow: hidden

.dropdownItem
  height: 42px
  font-size: 14px
  border-radius: 4px
  margin: 2px 0
  transition: all 0.2s
  
  &[data-selected]
    background: #f1e9ff
    color: #935af6
    font-weight: 500

.error
  font-size: 12px
  margin-top: 4px
  color: #fa5252

.formActions
  position: sticky
  bottom: 0
  background: #fff
  padding: 16px 0
  border-top: 1px solid #eaeaea
  margin-top: 24px
  z-index: 99

.buttonsWrapper
  max-width: 1200px
  margin: 0 auto
  display: flex
  justify-content: flex-end
  gap: 12px

.refDesignSection
  width: 100%
  display: flex
  gap: 24px

.uploadSection
  width: 33.33%
  min-width: 300px
  padding: 16px
  background: #f8f9fa
  border-radius: 8px
  border: 1px solid #eaeaea

.cardsSection
  flex: 1
  min-width: 0 