.chartWrapper
  padding: 20px

.statsGrid
  display: grid
  grid-template-columns: repeat(3, 1fr)
  gap: 20px
  margin-top: 24px

.statCard
  background: #f8f9fa
  padding: 16px
  border-radius: 8px
  border: 1px solid #eaeaea

.statLabel
  color: #495057
  font-size: 14px
  margin-bottom: 8px

.statValue
  color: #11142d
  font-size: 24px
  font-weight: 600 