.imageContainer
  position: relative
  width: 50px
  height: 50px
  border-radius: 4px
  overflow: hidden

.uploadOverlay
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, 0.3)
  display: flex
  align-items: center
  justify-content: center
  opacity: 0
  transition: opacity 0.2s
  cursor: pointer

  &:hover
    opacity: 1

.uploadButton
  padding: 4px
  height: 24px
  min-width: 24px
  background: transparent !important
  color: white !important
  
  &:hover
    background: rgba(255, 255, 255, 0.1) !important

.banIcon
  color: #FF5C5C 