@import ../../styles/helpers

.header
  display: flex
  justify-content: flex-end
  margin-bottom: 16px

.table
  margin-top: 16px
  
.actionColumn
  width: 100px
  text-align: center 