.container
  padding: 8px
  min-width: 200px

.linkItem
  width: 100%

.link
  color: #228be6
  text-decoration: none
  flex: 1
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  &:hover
    text-decoration: underline

.input
  flex: 1

.addButton
  align-self: flex-start 