@import ../../styles/helpers

.head
    +t
        margin-bottom: 32px
    +m
        flex-wrap: wrap
        margin-bottom: 24px
    .form
        margin-right: auto
        +t
            width: 250px
        +m
            width: 100%
            margin: 0 0 16px
    &.hidden
        +t
            display: none

.title
    margin: 0
    padding: 0
    font-size: 28px
    +m
        margin: 0 0 16px
    &::before
        display: none

.nav
    display: flex
    +m
        width: 100%

.link
    padding: 8px 16px
    border-radius: 8px
    +base1-s
    color: $n4
    transition: all .2s
    +m
        flex: 1
    &:hover,
    &.active
        color: $n7
        +dark
            color: $n1
    &.active
        background: $n3
        +dark
            background: $n6
    &:not(:last-child)
        margin-right: 8px
        +m
            margin-right: 0

.filters
    margin-left: 8px
    +t
        display: none

.details
    display: none

.row
    &.flex
        display: flex
        .table
            flex-shrink: 0
            width: 384px
            +w
                width: 350px
            +d
                width: 320px
            +t
                display: none
        .details
            display: block

.card 
    width: 100%
    padding-left: 30px

.scrollArea
    

.editor
    min-height: 364px

.quotaCard 
    width: 100%
    padding-left: 30px
    margin-top: 20px


// .root 
// 	background-color: light-dark(#fff, #2e2e2e) 
// 	box-shadow: var(--mantine-shadow-md) 
// 	border: rem(1px) solid light-dark(#f1f3f5, 	#424242) 
// .indicator 
// 	background-image: linear-gradient(
//     to right,
//     #c2255c,
//     #ffa94d
//   ) 
// .control 
// 	&: :before {
//     display: none 
// } 
// 	.label 
// 		&,
//         &: hover {
//         &[data-active] {
//             color: #fff 
