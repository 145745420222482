.card
  width: 100%
  border: 1px solid #eaeaea
  padding: 20px

.uploaderBox,
.pasteBox
  width: 100%
  height: 100px
  border-radius: 8px
  transition: all 0.2s
  cursor: pointer

.uploaderBox
  border: 2px dashed #eaeaea
  background: #f8f9fa
  
  &:hover
    background: #fff
    border-color: #935af6

.pasteBox
  border: 2px solid #eaeaea
  background: #fff
  
  &:hover,
  &:focus
    border-color: #935af6
    background: #faf8ff

.uploaderContent,
.pasteContent
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  color: #495057
  gap: 8px

.mediaSection
  width: 300px
  flex-shrink: 0

.contentSection
  flex: 1
  min-width: 0

.imageWrapper
  width: 100%
  border-radius: 8px
  overflow: hidden
  border: 1px solid #eaeaea

.image
  width: 100%
  height: auto
  object-fit: contain

.productsSection
  margin-bottom: 16px
  padding: 16px
  background: #f8f9fa
  border-radius: 8px
  border: 1px solid #eaeaea

.productsList
  margin-top: 16px
  display: flex
  flex-direction: column
  gap: 8px

.textInput
  height: 40px
  border: 1px solid #eaeaea
  border-radius: 6px
  font-size: 14px
  padding: 0 12px
  
  &:focus
    border-color: #935af6
    outline: none
    box-shadow: 0 0 0 2px rgba(147, 90, 246, 0.1)
  
  &::placeholder
    color: #adb5bd 

    
.productItem
  display: flex
  align-items: center
  padding: 8px 12px
  background: white
  border-radius: 6px
  border: 1px solid #eaeaea
  gap: 12px
  
  &:hover
    border-color: #935af6

.productContent
  flex: 1
  display: flex
  align-items: center
  gap: 12px
  min-width: 0

.productImage
  width: 40px
  height: 40px
  object-fit: contain
  background: #f8f9fa
  border-radius: 4px

.noteSection
  margin-top: 16px