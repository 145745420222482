.root
  width: 100%

.searchBar
  margin-bottom: 16px
  justify-content: space-between
  width: 100%

.searchInput
  flex: 1
  margin-right: 16px

.syncButton
  background: #935af6
  &:hover
    background: darken(#935af6, 5%)

.grid
  width: 100%

.card
  cursor: pointer
  transition: all 0.2s
  border: 2px solid transparent
  position: relative
  overflow: hidden
  
  &::before
    content: ""
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: rgba(147, 90, 246, 0.1)
    opacity: 0
    transition: opacity 0.2s
    z-index: 1
    pointer-events: none
  
  &.selected
    border-color: #935af6
    
    &::before
      opacity: 1
    
    .badge
      background: #935af6
      color: white

.image
  object-fit: contain
  background: #f8f9fa
  padding: 8px
  height: 200px
  width: 100%

.title
  margin: 12px 0
  font-size: 14px
  font-weight: 500
  height: 40px
  color: #11142D

.badge
  background: #f1e9ff
  color: #935af6
  transition: all 0.2s 