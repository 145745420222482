.playground
  padding: 1rem
  
  .tabs
    :global(.mantine-Tabs-list)
      border-bottom: none
      gap: 0.5rem
      margin-bottom: 1rem
      padding: 0.25rem
      border-radius: 8px
      
    :global(.mantine-Tabs-tab)
      font-size: 0.85rem
      font-weight: 500
      padding: 0.5rem 1rem
      transition: all 0.2s ease
      border-radius: 6px
      border: none
      color: #6b7280
      background: transparent
      
      &[data-active]
        background: #6366f1
        color: white
        font-weight: 600
        box-shadow: 0 2px 4px rgba(99, 102, 241, 0.2)
        
        &:hover
          background: #5558e6
      
      &:hover:not([data-active])
        background: rgba(99, 102, 241, 0.1)
        color: #6366f1

  .formCard
    margin-top: 1rem
    background: white
    border-radius: 12px
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.06)
    border: none
    
    :global(.mantine-TextInput-root),
    :global(.mantine-Select-root),
    :global(.mantine-Textarea-root)
      margin-bottom: 1rem
      
      label
        font-weight: 600
        color: #4b5563
        margin-bottom: 0.25rem
        font-size: 0.85rem
      
      input,
      textarea
        border-radius: 8px
        border: 2px solid #e5e7eb
        transition: all 0.2s ease
        font-size: 0.9rem
        
        &:focus
          border-color: #6366f1
          box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.1)
      
      textarea
        min-height: 80px
        padding: 0.5rem 0.75rem
    
    .imageUpload
      margin-bottom: 1rem
      
      .uploadArea
        border: 2px dashed #6366f1
        border-radius: 8px
        padding: 1.25rem
        text-align: center
        cursor: pointer
        transition: all 0.3s ease
        background: rgba(99, 102, 241, 0.03)
        min-height: 120px
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        gap: 0.5rem
        
        &:hover
          background: rgba(99, 102, 241, 0.08)
          transform: translateY(-1px)
        
        .icon
          color: #6366f1
          display: block
        
        .text
          color: #4b5563
          font-weight: 500
          font-size: 0.9rem
          margin: 0
          
      .preview
        margin-top: 0.75rem
        text-align: center
        
        img
          max-width: 200px
          max-height: 150px
          border-radius: 8px
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1)
          object-fit: contain

    .submitButton
      background: linear-gradient(45deg, #6366f1, #8b5cf6)
      height: 36px
      padding: 0 1.5rem
      font-size: 0.9rem
      font-weight: 600
      border-radius: 8px
      transition: all 0.3s ease
      
      &:hover
        transform: translateY(-1px)
        box-shadow: 0 2px 8px rgba(99, 102, 241, 0.3)

    :global(.mantine-Paper-root)
      border: none
      box-shadow: none
      
    :global(.mantine-Table-root)
      border: 1px solid #e2e8f0
      border-radius: 12px
      overflow: hidden
      
    :global(.mantine-TableScrollContainer-root)
      border-radius: 12px
      
    :global(.mantine-Pagination-root)
      margin: 1rem
      display: flex
      justify-content: flex-end
      
      :global(.mantine-Pagination-control)
        transition: all 0.2s ease
        
        &:hover
          background-color: #f1f5f9
          border-color: #cbd5e1
          
        &[data-active]
          background-color: #6366f1
          border-color: #6366f1
          color: white

      :global(.mantine-Pagination-item)
        margin: 0 4px
      
    :global(.mantine-Badge-root)
      text-transform: capitalize
      font-weight: 500
      letter-spacing: 0.3px
      
    .imageCell
      display: flex
      align-items: center
      justify-content: center
      padding: 4px
      
      :global(.mantine-Image-root)
        border-radius: 8px
        overflow: hidden
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05)
        transition: transform 0.2s ease
        
        &:hover
          transform: scale(1.05)

  .modalContent
    :global(.mantine-Card-root)
      box-shadow: none
      border-radius: 0
      
    :global(.mantine-TextInput-root),
    :global(.mantine-Select-root),
    :global(.mantine-Textarea-root)
      margin-bottom: 1rem
      
      label
        font-weight: 600
        color: #4b5563
        margin-bottom: 0.25rem
        font-size: 0.85rem
