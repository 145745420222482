.head-cells {
  font-weight: bold;
  color: #000000;
  width: 100%;
  padding: 0;
  background-color: #f2f3f5;
  height: 70px;
  border-right: 1px solid #d1d6e3;
}

.head-cells > div {
  align-items: center !important;
  justify-content: center !important;
  text-align: center;
}

.body-cells {
  vertical-align: middle;
  text-align: center;
}

.SKU {
  background-color: #36454f;
  color: #ffffff;
  width: 100%;
  height: 70px;
  border-right: 1px solid #d1d6e3;
}

.SKU > div {
  align-items: center !important;
  justify-content: center !important;
}
.edit-header {
  width: 100%;
  color: #000000;
  background-color: #fefff0;
  height: 70px;
  border-right: 1px solid #d1d6e3;
}
.edit-header > div {
  align-items: center !important;
  justify-content: center !important;
}

.default-header {
  width: 100%;
  color: #000000;
  background-color: #ffffff;
  height: 70px;
  border-right: 1px solid #d1d6e3;
}
.default-header > div {
  align-items: center !important;
  justify-content: center !important;
}
.mantine-Table-thead {
  position: sticky !important;
}
