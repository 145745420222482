.card
    margin-bottom: 8px
    padding-left: 30px
    &:not(:last-child)
        margin-bottom: 8px

.classCardHead
    margin-bottom: 16px




.title
    margin: 0
    padding: 0
    font-size: 28px
    &::before
        display: none
