@import ../../styles/helpers

.card 
    width: 100%
    padding-left: 30px

.title
    margin: 0
    padding: 0
    font-size: 28px
    +m
        margin: 0 0 16px
    &::before
        display: none

.head
    margin-bottom: 44px
    display: flex
    justify-content: space-between
    +t
        margin-bottom: 32px
    +m
        flex-wrap: wrap
        margin-bottom: 24px
    .form
        margin-right: auto
        +t
            width: 250px
        +m
            width: 100%
            margin: 0 0 16px