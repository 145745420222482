@import ../../styles/helpers

.wrapper
    margin: 0 -12px
    +t
        margin: 0

.table
    display: table
    width: 100%
    +t
        display: block

.row
    display: table-row
    background-color: #646A73 
    border-radius: 20px

    +t
        display: none

.col
    position: relative
    display: table-cell
    padding: 8px 18px 12px
    vertical-align: middle
    +caption1
    color: #ffffff
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
    &:first-child
        width: 56px
        font-size: 13px
        &:after
            left: 12px
    // &:last-child
    //     padding-right: 164px
    //     &:after
    //         right: 12px

.foot
    margin-top: 20px
    text-align: center
    +t
        margin-top: 0
    .button
        .loader
            transform: scale(.8)
            margin: 0 18px 0 8px