.imageWrapper
  cursor: pointer
  transition: transform 0.2s
  &:hover
    transform: scale(1.05)

.thumbnail
  border: 1px solid #eee
  background: #fff

.modal
  :global(.mantine-Modal-content)
    background: transparent
    box-shadow: none
    
  :global(.mantine-Modal-header)
    display: none

.modalContent
  display: flex
  justify-content: center
  align-items: center
  cursor: zoom-out

.fullImage
  max-width: 90vw
  max-height: 90vh
  object-fit: contain
  border-radius: 4px 