.searchWrapper
  width: 100%
  max-width: 300px

.input
  height: 36px
  border: 1px solid #eaeaea
  border-radius: 6px
  font-size: 14px
  padding-right: 36px !important
  
  &:focus
    border-color: #935af6
    outline: none
    box-shadow: 0 0 0 2px rgba(147, 90, 246, 0.1)
  
  &::placeholder
    color: #adb5bd

.inputWrapper
  position: relative

.searchIcon
  color: #495057
  transition: all 0.2s
  
  &:hover
    color: #935af6
    background: rgba(147, 90, 246, 0.1) 