.modalContent
  display: flex
  flex-direction: column
  max-height: 90vh
  border-radius: 12px
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1)

.modalHeader
  margin-bottom: 0
  padding: 20px 24px
  border-bottom: 1px solid #eaeaea
  background: #fcfcfd

.modalTitle
  font-weight: 600
  font-size: 20px
  color: #11142d

.modalBody
  padding: 0
  display: flex
  flex-direction: column
  flex: 1
  overflow: hidden
  background: #fff

.header
  background: #f8f9fa
  border-bottom: 1px solid #eaeaea

.headerTop
  padding: 12px 24px
  display: grid
  grid-template-columns: 1fr auto 1fr
  align-items: center
  gap: 12px

.batchText
  color: #935af6
  background: #f1e9ff
  padding: 6px 12px
  border-radius: 6px
  font-size: 14px
  font-weight: 500
  justify-self: start

.marketTrend
  background: #935af6
  color: white
  font-weight: 500
  padding: 6px 12px
  border-radius: 6px
  text-transform: uppercase
  justify-self: center

.owner
  color: #495057
  font-weight: 500
  font-size: 15px
  justify-self: end

.field
  background: white
  padding: 8px 16px
  border-radius: 6px
  border: 1px solid #eaeaea
  min-width: 180px

.label
  color: #495057
  font-size: 12px
  margin-bottom: 2px
  font-weight: 500

.value
  color: #11142d
  font-size: 14px
  font-weight: 500

.content
  flex: 1
  overflow: auto
  padding: 24px

.mainContent
  display: grid
  grid-template-columns: 3fr 7fr
  gap: 24px
  margin-bottom: 32px

.refSection
  min-width: 0
  max-width: 400px

.productSection
  min-width: 0
  flex: 1

.sectionTitle
  font-weight: 600
  color: #11142d
  margin-bottom: 20px
  font-size: 16px
  display: flex
  align-items: center
  
  &::before
    content: ""
    width: 4px
    height: 16px
    background: #935af6
    margin-right: 8px
    border-radius: 2px

.refImage
  background: white
  padding: 24px
  border-radius: 12px
  border: 1px solid #eaeaea
  display: flex
  justify-content: center
  height: 100%

.image
  width: 100%
  height: 100%
  object-fit: contain

.table
  width: 100%
  height: 100%
  background: white
  border: 1px solid #eaeaea
  border-radius: 12px
  overflow: hidden
  border-collapse: collapse

.tableHead
  background: #f8f9fa

.tableHeadCell
  color: #495057
  font-weight: 600
  font-size: 14px
  padding: 12px 16px
  border-bottom: 1px solid #eaeaea
  text-align: left

.tableCell
  padding: 12px 16px
  color: #495057
  font-size: 14px
  border-bottom: 1px solid #eaeaea

.productImage
  width: 40px
  height: 40px
  object-fit: contain
  background: #f8f9fa
  border-radius: 4px
  border: 1px solid #eaeaea

.removeIcon
  color: #495057
  cursor: pointer
  transition: all 0.2s

  &:hover
    color: #dc3545

.footer
  padding: 16px 24px
  border-top: 1px solid #eaeaea
  display: flex
  justify-content: flex-end
  gap: 12px
  background: #fff

.button
  min-width: 100px
  height: 40px
  border-radius: 6px
  font-weight: 500
  cursor: pointer
  transition: all 0.2s
  border: none

.buttonLight
  composes: button
  background: white
  border: 1px solid #eaeaea
  color: #495057
  
  &:hover
    background: #f8f9fa

.buttonPrimary  
  composes: button
  background: #935af6
  color: white
  
  &:hover
    background: darken(#935af6, 5%) 