.head-cells {
  font-weight: bold;
  color: #000000;
  width: 100%;
  padding: 0;
  background-color: #E5E4E2;
  height: 70px;
  border-right: 1px solid #d1d6e3;
  text-align: center !important;
}

.head-cells>div {
  align-items: center !important;
  justify-content: center !important;
}

.edit-cells {
  color: #ffffff;
  background-color: #935AF6;
  width: 100%;
  height: 70px;
  border-right: 1px solid #d1d6e3;
  text-align: center !important;
}

.edit-cells>div {
  align-items: center !important;
  justify-content: center !important;
}

.body-cells {
  vertical-align: middle !important;
  text-align: center !important;
  padding: 12px 16px !important;
}

.toolbar {
  padding: 16px;
  background-color: rgb(239, 240, 241);
  border-radius: 8px;
  margin-bottom: 16px;
} 