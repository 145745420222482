@import ../../styles/helpers

.link
    text-decoration: none
    transition: all .2s
    font-weight: 500
    font-size: 13px
    line-height: 1.5
    display: inline-flex
    align-items: center
    justify-content: center
    min-width: 80px
    
    &:hover
        transform: translateY(-1px)
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05)
    
    &:active
        transform: translateY(0)
    
    +dark
        background: rgba($p1, 0.1)
        
        &:hover
            background: rgba($p1, 0.15)
            borderColor: rgba($p1, 0.3) 