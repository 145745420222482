.actionButton
  width: 24px
  height: 24px
  border-radius: 4px
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
  transition: all 0.2s
  
  &:hover
    transform: scale(1.05)
    
  &.success
    background-color: #D9F5D6
    color: #62D256
    border: 1px solid #62D256
    
    &:hover
      background-color: #c5ebc1
      
  &.error
    background-color: #FFE9E9
    color: #FF5C5C
    border: 1px solid #FF5C5C
    
    &:hover
      background-color: #ffd5d5

  &.loading
    opacity: 0.7
    cursor: wait
    pointer-events: none

    &:hover
      transform: none 