@import ../../styles/helpers

.label
    margin-bottom: 14px
    +base2
    color: $n5
    +dark
        color: $n3


.editorButton
    position: relative
.ql-editor
    min-height: 400px