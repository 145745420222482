.card
    margin-bottom: 8px
    padding-left: 30px
    &:not(:last-child)
        margin-bottom: 8px
    overflow: unset

.classCardHead
    margin-bottom: 16px



.classScaleSpanTitle
    background-color: #91DE9E
    padding: 4px
    border-radius: 5px