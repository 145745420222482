

.content
  flex: 1
  overflow: auto
  padding: 24px
  min-height: 400px

.footer
  padding: 16px 24px
  border-top: 1px solid #eaeaea
  display: flex
  justify-content: flex-end

.header
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 20px

.search
  width: 300px

.loader
  flex: 1
  display: flex
  align-items: center
  justify-content: center

.grid
  flex: 1
  margin: 0
  overflow-y: auto
  padding: 0 10px

.card
  cursor: pointer
  transition: all 0.2s
  border: 2px solid transparent
  
  &:hover
    transform: translateY(-2px)
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1)
  
  &.selected
    border-color: #935af6
    background: rgba(147, 90, 246, 0.05)

.image
  object-fit: contain
  background: #f8f9fa
  padding: 8px

.title
  margin: 12px 0
  font-size: 14px
  font-weight: 500
  height: 40px

.badge
  background: #f1e9ff
  color: #935af6

.selectedProducts
  margin: 16px 0
  padding: 16px
  background: #f8f9fa
  border-radius: 8px
  border: 1px solid #eaeaea

.selectedList
  display: flex
  flex-wrap: wrap
  gap: 8px

.selectedItem
  display: flex
  align-items: center
  justify-content: space-between
  padding: 8px 12px
  background: white
  border-radius: 6px
  border: 1px solid #eaeaea
  min-width: 200px
  
  &:hover
    background: #fff
    border-color: #935af6

.selectedContent
  display: flex
  align-items: center
  gap: 8px
  flex: 1
  min-width: 0

.selectedImage
  object-fit: contain
  background: #f8f9fa
  border-radius: 4px 