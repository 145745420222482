@import ../../styles/helpers

.head
    margin-bottom: 44px
    +t
        margin-bottom: 32px
    +m
        flex-wrap: wrap
        margin-bottom: 24px
    .form
        margin-right: auto
        +t
            width: 250px
        +m
            width: 100%
            margin: 0 0 16px
    &.hidden
        +t
            display: none

.title
    margin-right: 24px
    +m
        margin: 0 0 16px

.nav
    display: flex
    +m
        width: 100%

.link
    padding: 8px 16px
    border-radius: 8px
    +base1-s
    color: $n4
    transition: all .2s
    +m
        flex: 1
    &:hover,
    &.active
        color: $n7
        +dark
            color: $n1
    &.active
        background: $n3
        +dark
            background: $n6
    &:not(:last-child)
        margin-right: 8px
        +m
            margin-right: 0

.filters
    margin-left: 8px
    +t
        display: none

.details
    display: none

.row
    &.flex
        display: flex
        .table
            flex-shrink: 0
            width: 384px
            +w
                width: 350px
            +d
                width: 320px
            +t
                display: none
        .details
            display: block

.card 
    width: 100%
    padding-left: 30px

.scrollArea
    