@import ../../styles/helpers

.modalTitle
  font-weight: 600
  font-size: 20px
  color: $n7
  margin-bottom: 24px

.modalContent
  padding: 16px

.content
  padding: 0 16px

.inputWrapper
  margin-bottom: 16px

.inputLabel
  font-weight: 500
  font-size: 14px
  margin-bottom: 8px
  color: $n7

.inputRequired
  color: #fa5252
  margin-left: 4px

.input
  height: 36px
  border: 1px solid #dee2e6
  transition: all 0.2s
  &:hover
    border-color: $p1
  &:focus
    border-color: $p1
    box-shadow: 0 0 0 2px rgba($p1, 0.1)

.radioWrapper
  margin-bottom: 16px

.radioGroupLabel
  font-weight: 500
  font-size: 14px
  color: $n7
  margin-bottom: 8px

.radioLabel
  font-size: 14px
  color: $n7

.actions
  margin-top: 24px
  padding-top: 24px
  border-top: 1px solid #dee2e6
  display: flex
  justify-content: flex-end
  gap: 12px

.button
  height: 36px
  padding: 0 16px
  font-weight: 500
  font-size: 14px 