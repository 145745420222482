@import ../../../styles/helpers

.modal
  :global(.mantine-Modal-title)
    font-weight: 600
    font-size: 20px
    color: $n7
    margin-bottom: 24px

.content
  padding: 0

.rowCount
  background: #f8f9fa
  padding: 20px
  border-radius: 8px
  margin-bottom: 24px

  :global(.mantine-NumberInput-label)
    font-weight: 500
    font-size: 14px
    margin-bottom: 8px
    color: $n7

  :global(.mantine-NumberInput-description)
    color: $n5
    font-size: 12px
    margin-bottom: 8px

  :global(.mantine-NumberInput-input)
    height: 36px
    border: 1px solid #dee2e6
    transition: all 0.2s
    &:hover
      border-color: $p1
    &:focus
      border-color: $p1
      box-shadow: 0 0 0 2px rgba($p1, 0.1)

.tableWrapper
  margin-top: 20px
  border: 1px solid #dee2e6
  border-radius: 8px
  overflow: hidden

  :global(.mantine-Table-root)
    min-width: 600px

  :global(.mantine-Table-th)
    background: #f8f9fa
    text-align: left
    padding: 12px 16px
    font-weight: 500
    color: $n7
    font-size: 14px
    border-bottom: 1px solid #dee2e6

    span[c="red"]
      margin-left: 4px
      color: #fa5252

  :global(.mantine-Table-td)
    padding: 12px 16px
    vertical-align: middle
    border-bottom: 1px solid #dee2e6

    .mantine-Select-root,
    .mantine-TextInput-root,
    .mantine-NumberInput-root
      width: 100%

      input
        height: 36px
        transition: all 0.2s
        &:hover
          border-color: $p1
        &:focus
          border-color: $p1
          box-shadow: 0 0 0 2px rgba($p1, 0.1)

  :global(.mantine-Table-tr)
    &:hover
      background: #f8f9fa

.actions
  margin-top: 24px
  padding-top: 24px
  border-top: 1px solid #dee2e6
  display: flex
  justify-content: flex-end
  gap: 12px

  :global(.mantine-Button-root)
    height: 36px
    padding: 0 16px
    font-weight: 500
    font-size: 14px

    &[data-variant="light"]
      background: #f1f3f5
      color: $n7
      &:hover
        background: #e9ecef

    &[data-variant="filled"]
      background: $p1
      &:hover
        background: darken($p1, 5%) 